// Route constants for the application
export default {
  Init: '/init', // Path for the welcome route
  Onboarding: '/onboarding', // Path for the onboarding route
  Login: 'auth/login', // Path for the login route
  Register: 'auth/register', // Path for the registration route
  varietyPlant: 'garden/cultivation',
  plantDetails:':id',
  SeedDetails :":id",
  home: '/home',
  agenda: '/vegiwise/agenda',
  potager: '/garden',
  compte: '/profile',
};
